import { Theme, ThemeOptions } from "@mui/material";

export function getBrandThemeOptions(brandId: string, overrideTheme?: Theme) {
    const brandThemes = {
        // Dare to Drink Different
        "863d6a76-a591-4531-9e50-9609c64678c5": {
            palette: {
                mode: "light",
                primary: {
                    main: "#FCC00A",
                    // other brand themes are palette mode dark, so this one needs to be specified for button hover
                    dark: "#cca01d",
                    contrastText: "#000000",
                },
                secondary: {
                    main: overrideTheme?.palette?.secondary?.main ?? "#EB7955",
                    contrastText: "#ffffff",
                },
                background: {
                    default: overrideTheme?.palette?.background?.default ?? "#FFFFFF",
                    paper: "#EB7955",
                    paperContrastText: "#ffffff",
                },
                text: {
                    primary: overrideTheme?.palette?.text?.primary ?? "#000000",
                    secondary: "#ffffff",
                },
            },
            typography: {
                allVariants: {
                    fontFamily: "Breakers Slab",
                },
            },

            components: {
                MuiTypography: {
                    defaultProps: {
                        fontFamily: "Breakers Slab",
                    },
                    styleOverrides: {},
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            "& .MuiInputBase-root": {
                                color: "#000000",
                            },
                            "& .MuiInputBase-root fieldset": {
                                borderColor: "#000000",
                            },
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontFamily: "Breakers Slab",
                        },
                    },
                },
                MuiCheckbox: {
                    styleOverrides: {
                        root: {
                            color: overrideTheme?.palette?.text?.primary ?? "#000000",
                            paddingLeft: "0px",
                        },
                    },
                },
            },
        },
        // Heroes den Bosch
        "19e9244c-34e4-49d4-8b92-211f552142aa": {
            palette: {
                mode: "dark",
                primary: {
                    main: "#CB0A35",
                    contrastText: "#ffffff",
                },
                secondary: {
                    main: "#2A317C",
                    contrastText: "#ffffff",
                },
                background: {
                    default: "#2A317C",
                    paper: "#2A317C",
                    gradient: "#CB0A35",
                    paperContrastText: "#ffffff",
                },
                text: {
                    primary: "#ffffff",
                    secondary: "#000000",
                },
            },
            typography: {
                h1: {
                    color: "#ffffff !important",
                },
            },
            components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            "& .MuiInputBase-root": {
                                backgroundColor: "#ffffff ",
                                color: "#000000",
                            },
                        },
                    },
                },
            },
        },
        // Macron Store Deurne
        "39302cb9-0880-4398-9565-f9e516a3c97e": {
            palette: {
                mode: "dark",
                primary: {
                    main: "#01FFE5",
                    contrastText: "#000000",
                },
                secondary: {
                    main: "#16151F",
                    contrastText: "#01FFE5",
                },
                background: {
                    default: "#000000",
                    paper: "#3d3955",
                    gradient: "#292738",
                    paperContrastText: "#ffffff",
                },
                text: {
                    primary: "#ffffff",
                    secondary: "#ffffff",
                },
                card: {
                    main: "#3d3955",
                    dark: "#2a273b",
                    light: "#636077",
                },
            },
            typography: {
                h1: {
                    color: "#01FFE5 !important",
                },
            },
            components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            "& .MuiInputBase-root": {
                                backgroundColor: "#ffffff ",
                                color: "#000000",
                            },
                        },
                    },
                },
            },
        },
        // A Fish Named Fred
        "5cf1605c-19b4-11ee-be56-0242ac120002": {
            palette: {
                mode: "dark",
                primary: {
                    main: "#E84B0E",
                    contrastText: "#ffffff",
                },
                secondary: {
                    main: "#16151F",
                    contrastText: "#E84B0E",
                },
                background: {
                    default: "#001127",
                    paper: "#173B6A",
                    gradient: undefined,
                    paperContrastText: "#ffffff",
                },
                text: {
                    primary: "#ffffff",
                    secondary: "#ffffff",
                },
            },
            typography: {
                h1: {
                    color: "#E84B0E !important",
                },
            },
            components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            "& .MuiInputBase-root": {
                                backgroundColor: "#ffffff ",
                                color: "#000000",
                            },
                        },
                    },
                },
            },
        },
        // Maps Untold
        "627b00af-fbd4-4cac-baf8-25a91533d9c4": {
            palette: {
                mode: "dark",
                primary: {
                    main: "#E84B0E",
                    contrastText: "#ffffff",
                },
                secondary: {
                    main: "#16151F",
                    contrastText: "#E84B0E",
                },
                background: {
                    default: "#001127",
                    paper: "#173B6A",
                    gradient: undefined,
                    paperContrastText: "#ffffff",
                },
                text: {
                    primary: "#ffffff",
                    secondary: "#ffffff",
                },
            },
            typography: {
                h1: {
                    color: "#E84B0E !important",
                },
            },
            components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            "& .MuiInputBase-root": {
                                backgroundColor: "#ffffff ",
                                color: "#000000",
                            },
                        },
                    },
                },
            },
        },
        // BSO demo brand
        "a282cde6-2cbe-48a5-8e66-948cfe6f4c76": {
            palette: {
                mode: "dark",
                primary: {
                    main: "#2D549C",
                    contrastText: "#ffffff",
                },
                secondary: {
                    main: "#C1D6FF",
                    contrastText: "#ffffff",
                },
                tertiair: {
                    main: "#B43288",
                    contrastText: "#ffffff",
                },
                background: {
                    default: "#ffffff",
                    paper: "#C1D6FF",
                    paperContrastText: "#2D549C",
                },
                text: {
                    primary: "#ffffff",
                    secondary: "#000000",
                },
                info: {
                    main: "#FF8220",
                },
                success: {
                    main: "#2FB722",
                },
                error: {
                    main: "#D12525",
                },
            },
            typography: {
                h1: {
                    fontSize: "48px",
                    lineHeight: "72px",
                },
                h4: {
                    fontSize: "24px",
                    lineHeight: "36px",
                    textTransform: "none",
                },
                h5: {
                    fontSize: "20px",
                    lineHeight: "30px",
                    textTransform: "none",
                    letterSpacing: "normal",
                },
                h6: {
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "27px",
                },
                h7: {
                    fontSize: "16px",
                    lineHeight: "24px",
                },
                h8: {
                    fontSize: "14px",
                    lineHeight: "21px",
                },
            },
            components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            "& .MuiInputBase-root": {
                                backgroundColor: "#ffffff",
                                color: "#000000",
                            },
                        },
                    },
                },
                MuiSelect: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "#ffffff",
                            color: "#000000",
                        },
                        icon: {
                            color: "#000000",
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            color: "#000000",
                        },
                    },
                },

                MuiTypography: {
                    defaultProps: { textTransform: "none" },
                },
            },
        },
    } as Record<string, ThemeOptions>;

    return brandThemes[brandId];
}
