import { useNavigate } from "react-router-dom";
import { useClubProvider } from "../../service/ClubService";
import { useQueryGet } from "../../service/api/restApiQuery";
import { Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import FadeContainer from "../../components/Container/FadeContainer";
import { ImageWithFallback } from "../../components/Image/ImageWithFallback";

export type Team = {
    id: string;
    logo_url: string;
    name: string;
    rival_id: string;
    players: Player[];
};

export type Player = {
    id: string;
    team_id: string;
    mvp_count: string;
    name: string;
    number: number;
    display_number: string;
    portrait_url: string;
    role: string;
    birth_date: number;
};

export type DetailedPlayer = Player & {
    height: number;
    nationality: string;
    career: string[];
};

export const ClubTeamPage = () => {
    const { brand_id, brand_config } = useClubProvider();

    const { t } = useTranslation();

    const { data } = useQueryGet<Team>(["team"], "gateway", "/sport/sport/team?id=" + brand_id, {
        suspense: true,
    });

    return (
        <FadeContainer short>
            <title>{t("club.tabTitle.team", { brandName: brand_config.name })}</title>
            <TeamTitle />
            <div
                style={{
                    overflow: "hidden",
                }}
            >
                {data && data.players && (
                    <Grid container spacing={3}>
                        {data.players
                            .sort((a, b) => a.number - b.number)
                            .map((player) => {
                                return (
                                    <Grid item key={player.number} xs={6}>
                                        <PlayerCard {...player} />
                                    </Grid>
                                );
                            })}
                    </Grid>
                )}
            </div>
        </FadeContainer>
    );
};

export default ClubTeamPage;

const TeamTitle = () => {
    const theme = useTheme();

    return (
        <div
            style={{
                margin: theme.spacing(6),
                fontSize: "clamp(2em, 15vw, 80px)",
                textAlign: "center",
                lineHeight: ".9em",
            }}
        >
            <div className="outline-text text-fade">TEAM</div>
            <div>ROSTER</div>
        </div>
    );
};

export const PlayerCard = ({ role, name, display_number, portrait_url, id }: Player) => {
    const { brand_id } = useClubProvider();
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <div
            onClick={() => {
                navigate(`/club/team/${brand_id}/speler/${id}`);
            }}
        >
            <PlayerCardBackground portrait_url={portrait_url} number={display_number} />
            <div
                style={{
                    marginTop: theme.spacing(1),
                    fontSize: "20px",
                    lineHeight: "22px",
                }}
            >
                {name.toUpperCase()}
            </div>
            <div
                style={{
                    fontSize: "16px",
                    lineHeight: "18px",
                    opacity: 0.7,
                }}
            >
                {role.toUpperCase()}
            </div>
        </div>
    );
};

const PlayerCardBackground = ({ number, portrait_url }: { number: string; portrait_url: string }) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                height: "240px",
            }}
        >
            <div
                style={{
                    fontSize: "clamp(5em, 75vw, 320px)",
                    lineHeight: ".7em",
                    fontFamily: "Heroes Den Bosch",
                    textAlign: "right",
                }}
            >
                {number}
            </div>

            <ImageWithFallback
                src={portrait_url}
                className="background-fade"
                containerSx={{
                    position: "absolute",
                    bottom: 0,
                    height: "70%",
                    width: "100%",
                }}
                style={{
                    objectPosition: "bottom",
                    WebkitMaskImage: "linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 85%,rgba(0, 0, 0, 0) 100%)",
                    maskImage: "linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 85%,rgba(0, 0, 0, 0) 100%)",
                }}
                objectFit="contain"
                type="rectangular"
            />
        </div>
    );
};
