import axios, { AxiosResponse } from "axios";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationOptions, UseQueryOptions, useQuery } from "react-query";
import { ContentComponentProp } from "../../components/Brand/ContentComponentAvatarIcon";
import { env } from "../../env.mjs";
import { BrandConfigGroup } from "../BrandConfigProvider";
import { AuthContextInterface, useAuthProvider } from "../auth/AuthProvider";
import { BrandCategory } from "./categoryService";
import { FetchMutateResponse } from "./fetchService";
import { FetchOptions, usePrefetchQuery, useQueryGet, useQueryPost } from "./restApiQuery";
import { BrandConfig } from "./schemeValidator";
import { Capacitor } from "@capacitor/core";

type ExtractType<T> = T extends { type: infer U } ? U : never;
export type ContentComponentType = ExtractType<ContentComponentProp>;

// TODO: remove optional fields like description
export type Brand = {
    id: string;
    url: string;
    name: string;
    description?: string;
    slogan?: string;
    email_address?: string;
    address?: string;
    completed?: boolean;
    visibility: BrandVisibility;
    exclusive: boolean;
    categories?: BrandCategory[];
    tags?: string[];
    content_components?: ContentComponent[];
};

export type ContentComponent = {
    content_id: string;
    type: ContentComponentType;
    highlighted: boolean;
};

export type BrandContextInterface = {
    brandLoading: boolean;
    connectedBrands: Brand[];
    notConnectedBrands: Brand[];
    getCurrentBrand: (brandId?: string) => Brand | undefined;
};

type NavigateToBrandKey = "navigate-brand" | "link-user" | "login-from-any";

export type useNavigateToBrandResponse = {
    redirect_url: string;
};

// this is the interface of the metadata contained in the redirect URL response
export type NavigateToBrandResponseParams = {
    login_url: string;
    email: string;
    password: string;
    redirect_url?: string;
};

export function useNavigateToBrand(
    brandId = "",
    redirectUrl = "/#",
    options?: UseMutationOptions<AxiosResponse<useNavigateToBrandResponse>, unknown, FetchOptions, unknown>,
    key = "navigate-brand" as NavigateToBrandKey,
    coupon_id?: string,
    marketingConsent?: string,
) {
    const query = useQueryPost<AxiosResponse<useNavigateToBrandResponse>>(
        [key],
        "gateway",
        `/brand/user/login-from-any?redirect_uri=${redirectUrl}`,
        options,
        {
            headers: {
                BrandId: brandId,
                "Destination-Key": coupon_id ?? "",
                "Marketing-Consent": marketingConsent ?? "false",
            },
        },
    );

    return {
        ...query,
        navigateToBrand: () => {
            query.mutate({});
        },
        navigateToBrandAsync: () => {
            query.mutateAsync({});
        },
    };
}

type LinkUserToBrandKey = "register-link" | "login-link";
export type ClientOrigin = "customer-portal" | "sso-popup";
export function useRegisterLink(
    options?: UseMutationOptions<FetchMutateResponse, unknown, FetchOptions, unknown>,
    key = "register-link" as LinkUserToBrandKey,
) {
    let pathname = `/brand/public/register-link`;

    if (window.opener && window.opener !== window) {
        pathname += "?to_customer_portal=false";
    }

    const mutation = useQueryPost([key], "gateway", pathname, options);

    return mutation;
}

export function useLoginLink(
    options?: UseMutationOptions<FetchMutateResponse, unknown, FetchOptions, unknown>,
    key = "login-link" as LinkUserToBrandKey,
) {
    let pathname = `/brand/public/login-link`;

    if (window.opener && window.opener !== window) {
        pathname += "?to_customer_portal=false";
    }

    const mutation = useQueryPost([key], "gateway", pathname, options);
    return mutation;
}

type ConnectedBrandsResponse = {
    connectedBrands: Brand[];
    notConnectedBrands: Brand[];
};

type BrandVisibility = "public" | "private";

export function useBrands(options?: UseQueryOptions<ConnectedBrandsResponse, unknown, ConnectedBrandsResponse>) {
    const authProvider = useAuthProvider();
    const { i18n } = useTranslation();

    const query = useQueryGet<ConnectedBrandsResponse>(
        ["user-brands"],
        "gateway",
        "/brand/user/brands?lang=" + i18n.language.split("-")[0],
        {
            enabled: authProvider.authBody?.accessToken !== undefined,
            ...options,
        },
    );

    const connectedBrands = query.data?.connectedBrands || [];
    const notConnectedBrands = query.data?.notConnectedBrands || [];

    const brands = useMemo(() => [...connectedBrands, ...notConnectedBrands], [connectedBrands, notConnectedBrands]);

    return {
        brands: brands,
        connectedBrands,
        notConnectedBrands,
        ...query,
    };
}

export function useBrands_bso_temporary(authProvider: AuthContextInterface, options?: UseQueryOptions) {
    const { authBody } = authProvider;
    const { i18n } = useTranslation();

    const query = useQuery(
        ["user-brands"],
        () => {
            return axios
                .get<ConnectedBrandsResponse>(
                    env.gatewayUrl + "/brand/user/brands?lang=" + i18n.language.split("-")[0],
                    {
                        headers: {
                            Authorization: `Bearer ${authBody?.accessToken}`,
                        },
                    },
                )
                .then((res) => res.data);
        },

        {
            enabled: !!authBody?.accessToken && Capacitor.isNativePlatform(),
            suspense: true,
        },
    );

    const connectedBrands = query.data?.connectedBrands || [];
    const notConnectedBrands = query.data?.notConnectedBrands || [];

    const brands = useMemo(() => [...connectedBrands, ...notConnectedBrands], [connectedBrands, notConnectedBrands]);

    return {
        brands: brands,
        connectedBrands,
        notConnectedBrands,
        ...query,
    };
}

export type BrandResponse = Brand & { connected: boolean };

export function useBrandById(brandId?: string, options?: UseQueryOptions<BrandResponse, unknown, BrandResponse>) {
    const { i18n } = useTranslation();
    const { authBody } = useAuthProvider();

    return useQueryGet<BrandResponse>(
        ["brand", "detail", brandId],
        "gateway",
        "/brand/user/brand?lang=" + i18n.language.split("-")[0],
        {
            enabled: !!brandId && !!authBody?.accessToken,
            ...options,
        },
        {
            headers: { BrandId: brandId || "", Authorization: `Bearer ${authBody?.accessToken}` },
        },
    );
}

export function usePrefetchBrandById(brandId?: string) {
    const { i18n } = useTranslation();
    const { authBody } = useAuthProvider();

    return usePrefetchQuery(
        ["brand", "detail", brandId],
        "gateway",
        "/brand/user/brand?lang=" + i18n.language.split("-")[0],
        {
            headers: { BrandId: brandId || "" },
        },
        {
            enabled: !!brandId && !!authBody?.accessToken,
        },
    );
}

const requiredCreateAccountFallback = ["givenName", "familyName", "email"];

export function getCurrentBrand(brands: Brand[], brandId?: string): Brand | undefined {
    return brands.find((b) => b.id === brandId);
}

export function useGetBrandConfigs() {
    return useQueryGet<BrandConfigGroup>(["brand-configs"], "gateway", `/brand/public/configs`);
}

type BrandLandingPageUrl = {
    brand_id: string;
    sub_path: string;
};
// set a landing page url by brand id to redirect the user from anyID homepage to the page url after installing the app
export function useCreateBrandLandingPageUrl(
    brandId: string | undefined,
    options?: UseMutationOptions<Pick<BrandLandingPageUrl, "brand_id">, unknown, FetchOptions, unknown>,
) {
    return useQueryPost<Pick<BrandLandingPageUrl, "brand_id">>(
        ["brand", brandId, "landing-page"],
        "gateway",
        "/brand/user/landing-page",
        options,
    );
}

/**
 * Check if there's a landing page to redirect. It's used for the WelcomeToConnectedBrandDrawer component, like for BSO brands for now.
 * returns 204 if there's no content
 */
export function useGetBrandLandingPageUrl(options?: UseQueryOptions<BrandLandingPageUrl>) {
    const { authBody } = useAuthProvider();
    return useQueryGet<BrandLandingPageUrl>(["brand", "landing-page"], "gateway", "/brand/user/landing-page", {
        enabled: !!authBody?.accessToken,
        ...options,
    });
}

export function useGetBrandLandingPageUrl_bso_temporary(authProvider: AuthContextInterface) {
    const { authBody } = authProvider;

    return useQuery(
        ["brand", "landing-page"],
        () => {
            return axios
                .get<BrandLandingPageUrl>(env.gatewayUrl + "/brand/user/landing-page", {
                    headers: {
                        Authorization: `Bearer ${authBody?.accessToken}`,
                    },
                })
                .then((res) => res.data);
        },

        {
            enabled: !!authBody?.accessToken && Capacitor.isNativePlatform(),
            suspense: true,
        },
    );
}

export function hasBrandEnvironment(brandConfig?: BrandConfig): boolean {
    // check deprecatede type "club" for backward compatibility
    return brandConfig?.type === "sport" || brandConfig?.type === "club" || brandConfig?.type === "bso";
}
