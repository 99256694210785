import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ButtonBase, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCapacitorProvider } from "../../service/capacitor/CapacitorProvider";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { Capacitor } from "@capacitor/core";
import { memo, useEffect, useMemo, useState } from "react";
import { useInAppNotifications } from "../../service/InAppNotificationProvider";

export function isCurrentPath(paths: string[]) {
    const parentPath = window.location.pathname.split("/").slice(0, 2).join("/");
    return paths.includes(parentPath);
}

export type NavigationItem = {
    paths: string[];
    icon: IconDefinition;
    selectedIcon: IconDefinition;
    title?: string;
    onClick?: () => void;
    hasBadge?: boolean;
};

function _BottomNav() {
    const [mobileKeyboardHeight, setMobileKeyboardHeight] = useState(0);

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { insets } = useCapacitorProvider();
    const theme = useTheme();

    const { hasUnopenedNotifications } = useInAppNotifications();

    function keyboardWillShow(ev: any) {
        const keyboardHeight = ev.keyboardHeight;
        setMobileKeyboardHeight(keyboardHeight);
    }
    function keyboardWillHide() {
        setMobileKeyboardHeight(0);
    }

    // todo: #1989 make it a custom hook
    // preven the footer to stick above the virtual keyborad in mobile
    useEffect(() => {
        window.addEventListener("keyboardWillShow", (ev: any) => {
            keyboardWillShow(ev);
        });

        window.addEventListener("keyboardWillHide", () => {
            keyboardWillHide();
        });

        return () => {
            window.removeEventListener("keyboardDidShow", keyboardWillShow);
            window.removeEventListener("keyboardWillHide", keyboardWillHide);
        };
    }, []);

    const navigationItems = useMemo(
        () =>
            [
                {
                    paths: ["/", "/home"],
                    icon: regular("house"),
                    selectedIcon: solid("house"),
                    title: t("bottomNav.home"),
                },
                {
                    paths: ["/aangesloten-merken"],
                    icon: regular("compass"),
                    selectedIcon: solid("compass"),
                    title: t("bottomNav.brandOverviewExplore"),
                },
                {
                    paths: ["/notificaties"],
                    icon: regular("bell"),
                    selectedIcon: solid("bell"),
                    title: t("bottomNav.notifications"),
                    hasBadge: hasUnopenedNotifications,
                },
            ] as NavigationItem[],
        [i18n.language, hasUnopenedNotifications],
    );

    if (
        [
            "/authenticator",
            "/login",
            "/pincode-wijzigen",
            "/auth",
            "/mobile",
            "/update-account",
            "/medewerker-link",
            "/login-link",
            "/redirect",
            "/compleet-account",
            "/welkom",
            "/quiz",
            "/brand/inloggen",
            "/bso/mijn-gegevens",
            "/user-group-link",
            "/add-pinned-shortcut",
        ].find((path) => window.location.pathname.includes(path))
    )
        return <></>;

    return (
        <Box
            sx={{
                position: "fixed",
                bottom: `${-mobileKeyboardHeight}px`,
                paddingBottom: insets?.bottom && Capacitor.getPlatform() === "ios" ? `${insets.bottom - 12}px` : "0px",

                width: "100%",

                justifyContent: "space-between",
                display: { xs: "flex", sm: "none" },
                alignItems: "center",

                background: theme.palette.mode === "dark" ? "rgba(45, 45, 74, 0.70)" : "#ffffff",
                backdropFilter: "blur(18px)",
                zIndex: 100,
            }}
        >
            {navigationItems.map((item) => {
                const selected = isCurrentPath(item.paths);

                return (
                    <ButtonBase
                        disableRipple={Capacitor.getPlatform() === "android"}
                        style={{
                            flexDirection: "column",
                            alignItems: "center",
                            minHeight: "60px",
                            width: "33%",
                            position: "relative",
                        }}
                        key={item.title}
                        onClick={() => {
                            if (window.location.pathname === item.paths[0]) {
                                window.scroll({ top: 0, behavior: "smooth" });
                                return;
                            }

                            navigate(item.paths[0]);
                        }}
                    >
                        <FontAwesomeIcon icon={selected ? item.selectedIcon : item.icon} size="xl" />
                        {item.hasBadge && (
                            <div
                                style={{
                                    height: "6px",
                                    width: "6px",
                                    borderRadius: "100%",
                                    background: "#54AB9A",
                                    position: "absolute",
                                    transform: "translate(10px, -18px)",
                                }}
                            ></div>
                        )}

                        <Typography variant="iconText" mt={1} fontWeight={selected ? 600 : 500}>
                            {item.title}
                        </Typography>
                    </ButtonBase>
                );
            })}
        </Box>
    );
}

export const BottomNav = memo(_BottomNav);
