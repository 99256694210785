import { Box } from "@mui/material";
import { SxProps, styled } from "@mui/material/styles";
import FadeContainer from "./FadeContainer";
import { ReactNode } from "react";

// helps with the navigation margins

const _FullScreenRootContainer = styled(Box)(({ theme }) => {
    return {
        position: "fixed",
        zIndex: 1000,
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",

        // mobile
        [theme.breakpoints.down("sm")]: {
            height: "100svh",
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(0),
        },
        // desktop
        [theme.breakpoints.up("sm")]: {
            height: "100vh",
            display: "flex",
            flexDirection: "column",
        },
    };
});

export default function ({ children, sx }: { children: ReactNode; sx?: SxProps }) {
    return (
        <FadeContainer>
            <_FullScreenRootContainer sx={sx}>{children}</_FullScreenRootContainer>
        </FadeContainer>
    );
}
