import { z } from "zod";
/*
  This code safe parses the environment variables so we dont have to constantly check if they are defined or not
  Define your variable in the `envSchema` object using zod to validate the input
  Then deconstruct your env in the `rawEnv` object as shown
*/
const envSchema = z.object({
  gtag: z.string(),
  streamidIos: z.string(),
  streamidAndroid: z.string(),
  streamidWeb: z.string(),
  customerPortalUrl: z.string(),
  packageVersion: z.string(),
  cookieCode: z.string(),
  segmentWriteKey: z.string(),
  keycloakRealm: z.string(),
  postalCodeUrl: z.string(),
  postalCodeToken: z.string(),
  ssoClientIdSwinkels: z.string(),
  bundleId: z.string(),
  devEnv: z.union([z.literal("development"), z.literal("release"), z.literal("production")]),
  keycloakUrl: z.string(),
  gatewayUrl: z.string(),
  keycloakClientId: z.string(),
  cdnUrl: z.string(),
  fontAwesomeToken: z.string(),
  authenticationEncryptionSecret: z.string(),
});

const rawEnv = {
  gtag: process.env.REACT_APP_GTAG,
  streamidIos: process.env.REACT_APP_STREAMID_IOS,
  streamidAndroid: process.env.REACT_APP_STREAMID_ANDROID,
  streamidWeb: process.env.REACT_APP_STREAMID_WEB,
  customerPortalUrl: process.env.REACT_APP_CUSTOMER_PORTAL_URL,
  packageVersion: process.env.REACT_APP_VERSION,
  cookieCode: process.env.REACT_APP_COOKIE_CODE,
  segmentWriteKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
  keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM,
  postalCodeUrl: process.env.REACT_APP_POSTALCODE_URL,
  postalCodeToken: process.env.REACT_APP_POSTALCODE_TOKEN,
  ssoClientIdSwinkels: process.env.REACT_APP_SSO_CLIENT_ID_SWINKELS,
  bundleId: process.env.REACT_APP_BUNDLE_ID,
  devEnv: process.env.REACT_APP_DEV_ENV,
  keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL,
  gatewayUrl: process.env.REACT_APP_GATEWAY_URL,
  keycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENTID,
  cdnUrl: process.env.REACT_APP_CDN_URL,
  fontAwesomeToken: process.env.REACT_APP_FONT_AWESOME_TOKEN,
  authenticationEncryptionSecret: process.env.REACT_APP_AUTHENTICATION_ENCRYPTION_SECRET,
};

const _env = envSchema.safeParse(rawEnv);

const formatErrors = (errors) =>
  Object.entries(errors)
    .map(([name, value]) => {
      if (value && "_errors" in value) return `${name}: ${value._errors.join(", ")}\n`;
      return "";
    })
    .filter(Boolean);

if (_env.success === false) {
  console.error("❌ Invalid environment variables:\n", ...formatErrors(_env.error.format()));
  throw new Error("Invalid environment variables");
}

export const env = _env.data;
