import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

type SapMarketingConsentProps = {
    marketingConsent: boolean;
    setMarketingConsent: Dispatch<SetStateAction<boolean>>;
    required?: boolean;
};
export default function SapMarketingConsent({
    marketingConsent,
    setMarketingConsent,
    required,
}: SapMarketingConsentProps) {
    const { t } = useTranslation();

    return (
        <FormControlLabel
            onChange={() => setMarketingConsent((prev) => !prev)}
            control={<Checkbox size="small" checked={marketingConsent} />}
            required={required}
            sx={{
                "& .MuiTypography-root": {
                    fontFamily: "Roboto",
                    color: "text.primary",
                },
            }}
            label={t("registerLink.consentMarketingNewsletter")}
        />
    );
}
